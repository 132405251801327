
import React from "react";

import "../../../../css/ui/popUps/buyChips.css";
import { useEffect, useState } from "react";
import close_1 from './../../../../assets/images/table/close_1.svg';
import dollar from './../../../../assets/images/table/dollar.svg';
// import slider_thumb from '../../../../assets/images/table/slider_thumb.png';
import { withTranslation } from 'react-i18next';
import fileName from "../../../../jsconfig";
import Screen from "../../../utils/screen";
// import Config from '../../../../config';
// import useMemo from "react";
// import { useTable } from "react-table";

import UM from "../../../utils/utilityMethods";

function BuyChips(props) {
  // console.log(props.data)
  // console.log(props.onMoneyExchangeInfo)
  // console.log(props.usdTable)
  // console.log(props.playerBalance)
  // var config = new Config();
  // console.log("props from Buy chips window are");
  // console.log(props);

  // const columns = useMemo(() => {
  //   if (props.onMoneyExchangeInfo && props.onMoneyExchangeInfo.hasOwnProperty("attr")) {
  //     return [
  //       {
  //         Header: 'Wallet',
  //         accessor: props.Balance.attr.wallet,
  //       },
  //       {
  //         Header: "Available",
  //         accessor: props.Balance.attr.cash,
  //       },
  //       {
  //         Header: "Rate",
  //         accessor: "",
  //       },
  //       {
  //         Header: "Amount to Receive in USD",
  //         accessor: "",
  //       }
  //     ];
  //   } else {
  //     return [];
  //   }
  // }, [props.onMoneyExchangeInfo]);

  // const data = useMemo(() => props.onMoneyExchangeInfo, [props.onMoneyExchangeInfo]);
  // const tableInstance = useTable({
  //   columns,
  //   data,
  // });
  // const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;


  const [min, setMin] = useState(props.data.min);
  const [max, setMax] = useState(props.data.max);
  // const [available, setAvailable] = useState(props.data.available);
  const [newAvlBal, setNewAvlBal] = useState(props.data.newAvlBal);
  // const [newAvlBal, setNewAvlBal] = useState(props.data.max);
  const [newMinBal, setNewMinBal] = useState(props.data.newMinBal);
  const [newMaxBal, setNewMaxBal] = useState(props.data.newMaxBal);
  const [time, setTime] = useState(Math.floor(props.data.time - 1));
  const [showTimer, setShowTimer] = useState(props.data.runTimer);
  const [btnState, setbtnState] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [errorMsgText, setErrorMsgText] = useState(false);
  const [pausTheTime, setPausTheTime] = useState(true);
  const [amount, setAmount] = useState(min);
  const [inputValues, setInputValues] = useState({
    cash: 0,
    bonus: 0,
  });
  // console.log(newAvlBal, props.data.newAvlBal, props.data.max)
  // useEffect(() => {
  //   console.log(newAvlBal, props.data.newAvlBal, props.data.max)
  //   let isSubscribed = true;
  //   setMin(props.data.min);
  //   setAvailable(props.data.available);
  //   setNewAvlBal(props.data.newAvlBal);
  //   setNewMinBal(props.data.newMinBal);
  //   setNewMaxBal(props.data.newMaxBal);
  //   setMax(props.data.max);
  //   setAmount(props.data.min);
  //   setTime(props.data.time);
  //   setShowTimer(props.data.runTimer);
  //   return () => (isSubscribed = false)
  // }, [props.data]);


  useEffect(() => {
    console.log(props.data.newAvlBal, props.data.max);

    let isSubscribed = true;

    if (isSubscribed) {
      setMin(props.data.min);
      // setAvailable(props.data.available);
      setNewAvlBal(props.data.newAvlBal);
      setNewMinBal(props.data.newMinBal);
      setNewMaxBal(props.data.newMaxBal);
      setMax(props.data.max);
      setAmount(props.data.min);
      setTime(Math.floor(props.data.time - 1));
      setShowTimer(props.data.runTimer);
    }

    // Cleanup function to prevent memory leaks
    return () => {
      isSubscribed = false;
    };
  }, [props.data]);




  // useEffect(() => {
  //   let isSubscribed = true;
  //   // console.log(props.data);
  //   if (Number(props.data.available) < Number(props.data.min)) {
  //     setbtnState(true)
  //     setErrorMsg(true)
  //     setErrorMsgText("Your minimum balance shouldn't be greater then maximum balance..!")
  //   } else if (Number(props.data.min) == 0 && Number(props.data.max) == 0) {
  //     setbtnState(true)
  //     setErrorMsg(true)
  //     setErrorMsgText("You can't bring more chips to this table..!")

  //   } else if (Number(props.data.available) === 0) {
  //     setbtnState(true)
  //     setErrorMsg(true)
  //     setErrorMsgText("Please check  your balance..!")
  //   }
  //   else {
  //     setErrorMsgText("")
  //     setErrorMsg(false)
  //     setbtnState(false)
  //   }
  //   // if (props.data.runTimer) {
  //   // console.log(props.data.runTimer)
  //   let t = time;
  //   const buyChipsTimer = setInterval(() => {
  //     if (props.data.runTimer) {
  //       if (t > 0) {
  //         t = t - 1;
  //         setTime(t);
  //       } else {
  //         clearInterval(buyChipsTimer);
  //         props.setAction("hideBuyChips1");
  //       }
  //     }
  //   }, 1000);
  //   // }
  //   return () => {
  //     isSubscribed = false
  //     clearInterval(buyChipsTimer);
  //   }
  // }, [props, time, showTimer]);

  useEffect(() => {
    const updateScale = () => {
      const element = document.querySelector('.popup_1_in');
      if (element) {
        // Get the screen height
        const screenHeight = window.innerHeight;

        // Define minimum and maximum heights for scaling
        const minHeight = 375; // Minimum screen height for scaling
        const maxHeight = 812; // Maximum screen height for scaling

        // Define minimum and maximum scale factors
        const minScale = 0.85; // Minimum scale factor
        const maxScale = 1; // Maximum scale factor

        // Calculate scale factor based on screen height
        let scale = (screenHeight - minHeight) / (maxHeight - minHeight) * (maxScale - minScale) + minScale;

        // Clamp the scale to be within the minScale and maxScale bounds
        scale = Math.max(minScale, Math.min(maxScale, scale));

        // Apply the scale transformation
        element.style.transform = `scale(${scale})`;
        // element.style.transformOrigin = 'top left';
        // Adjust origin if needed
      }
    };

    // Initial call to set scale on mount
    updateScale();

    // Set up event listener for screen size changes
    window.addEventListener('resize', updateScale);

    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', updateScale);
  }, []);



  // useEffect(() => {
  //   let isSubscribed = true;
  //   // console.log(props.data);
  //   if (Number(props.data.available) < Number(props.data.min)) {
  //     setbtnState(true)
  //     setErrorMsg(true)
  //     setErrorMsgText("Your minimum balance shouldn't be greater then maximum balance..!")
  //   } else if (Number(props.data.min) == 0 && Number(props.data.max) == 0) {
  //     setbtnState(true)
  //     setErrorMsg(true)
  //     setErrorMsgText("You can't bring more chips to this table..!")

  //   } else if (Number(props.data.available) === 0) {
  //     setbtnState(true)
  //     setErrorMsg(true)
  //     setErrorMsgText("Please check  your balance..!")
  //   }
  //   else {
  //     setErrorMsgText("")
  //     setErrorMsg(false)
  //     setbtnState(false)
  //   }
  //   // if (props.data.runTimer) {
  //   // console.log(props.data.runTimer)
  //   let t = time;
  //   const buyChipsTimer = setInterval(() => {
  //     if (props.data.runTimer) {
  //       if (t > 0) {
  //         t = t - 1;
  //         setTime(t);
  //       } else {
  //         clearInterval(buyChipsTimer);
  //         props.setAction("hideBuyChips1");
  //       }
  //     }
  //   }, 1000);
  //   // }
  //   return () => {
  //     isSubscribed = false
  //     clearInterval(buyChipsTimer);
  //   }
  // }, [props, time, showTimer]);
  var buyChipsTimer;
  useEffect(() => {
    // let isSubscribed = true;
    if (Number(newAvlBal) < Number(props.data.min)) {
      setbtnState(true)
      setErrorMsg(true)
      // setErrorMsgText("Your minimum balance shouldn't be greater then maximum balance..!")
      // if (props.usdTable) {
      if (header === `Add Chips - ${props.usdTable ? "USD" : "CHP"}`) {
        setErrorMsgText(`You don't have enough money in ${props.usdTable ? "USD" : "CHP"} wallet. You can exchange money from other wallet.`);
      }
      if (props.onMoneyExchangeInfo.hasOwnProperty("attr")) {
        // console.log(props.onMoneyExchangeInfo.attr.cash)
        setNewAvlBal(props.onMoneyExchangeInfo.attr.cash);
      }
      // } else {
      //   setErrorMsgText("Your current balance is less than Minimum buy-in");
      // }
    } else {
      setErrorMsgText("");
      setErrorMsg(false);
      setbtnState(false);
    }

  }, [props]);

  useEffect(() => {
    if (props.data.runTimer && pausTheTime) {
      let t = Math.floor(Number(time));
      buyChipsTimer = setInterval(() => {
        if (t > 0) {
          t = t - 1;
          setTime(t);
        } else {
          clearInterval(buyChipsTimer);
          props.setAction("hideBuyChips");
        }
      }, 1000);
    }

    return () => {
      // isSubscribed = false;
      clearInterval(buyChipsTimer);
    };
  }, [time])

  const onClickClose = () => {
    props.network.send(`<CancelBuyChips />`);
    props.setAction("hideBuyChips");
  }

  const onClickBuy = () => {
    console.log(props.data.max)
    console.log(props.data.min)
    console.log(amount)
    if (Number(props.data.max) < Number(props.data.min)) {
      // if (Number(props.data.min) > Number(amount)) {
      setErrorMsg(true);
      setErrorMsgText("balance is low")
    } else {
      setErrorMsg(false);
      setErrorMsgText("");
      if (amount === 0) {
        setErrorMsg(true)
        setErrorMsgText("Minimum buy 1 chip")
      }
      else if (Number(props.data.min) > Number(amount)) {
        setErrorMsg(true);
        setErrorMsgText(`please check minimun buyin ${Number(props.data.min)}`);
      } else {
        setErrorMsg(false)
        setErrorMsgText("")
        if (Number(props.data.min) <= Number(amount)) {
          props.network.send(`<BuyChips amount="${amount}"/>`);
          props.setAction("hideBuyChips");
        }

      }

    }
  };
  const [header, setHeader] = useState(`Add Chips - ${props.usdTable ? "USD" : "CHP"}`);

  // const onClickExchange = () => {
  //   setErrorMsgText("");
  //   if (header === `Add Chips - ${props.usdTable ? "USD" : "CHP"}`) {
  //     setHeader("Currency Exchange");
  //     props.network.send(`<GetMoneyExchangeInfo wallet=${props.usdTable ? "'USD'" : "'CHP'"}  />`);
  //     setTimeout(() => {
  //       props.network.send(`<CancelBuyChips />`);
  //     }, 100);
  //     props.network.send(`<GetMoneyExchangeInfo wallet=${props.usdTable ? "'USD'" : "'CHP'"}  />`);
  //     // props.network.send(`<GetMoneyExchangeInfo wallet="CHP"  />`);
  //   } else {
  //     if (Number(inputValues['cash']) > 0) {
  //       props.network.send(`<ExchangeMoney wallet=${props.usdTable ? "'USD'" : "'CHP'"}><Sources><ExchangeSource wallet=${!props.usdTable ? "'USD'" : "'CHP'"} cash="${inputValues['cash']}" /></Sources></ExchangeMoney>`);
  //     }
  //     if (Number(inputValues['bonus']) > 0) {
  //       setTimeout(() => {
  //         props.network.send(`<ExchangeMoney wallet=${props.usdTable ? "'USD'" : "'CHP'"}><Sources><ExchangeSource wallet=${!props.usdTable ? "'USD'" : "'CHP'"} bonus="${inputValues['bonus']}" /></Sources></ExchangeMoney>`);
  //       }, 100);
  //     }
  //     // props.network.send(`<CancelBuyChips />`);
  //     console.log(props.data.min)
  //     console.log(props.onMoneyExchangeInfo.attr.cash)
  //     // setNewAvlBal(props.onMoneyExchangeInfo.Balance.attr.cash);
  //     if (Number(props.data.min) <= Number(props.onMoneyExchangeInfo.attr.cash)) {
  //       setTimeout(() => {
  //         props.onGetTakeSeat();
  //       }, 500);
  //     }
  //     setHeader(`Add Chips - ${props.usdTable ? "USD" : "CHP"}`);
  //   }
  // }

  const onClickExchange = () => {
    setErrorMsgText("");

    if (header === `Add Chips - ${props.usdTable ? "USD" : "CHP"}`) {
      setHeader("Currency Exchange");
      props.network.send(`<GetMoneyExchangeInfo wallet=${props.usdTable ? "'USD'" : "'CHP'"}  />`);
      setPausTheTime(false);
      clearInterval(buyChipsTimer);
      setTimeout(() => {
        props.network.send(`<CancelBuyChips />`);
      }, 100);
      // props.network.send(`<GetMoneyExchangeInfo wallet="CHP"  />`);
    } else {
      // onClickCancel()

      // props.isGetingResponse("ExchangeMoney");

      if (Number(inputValues['cash']) > 0) {
        props.network.send(`<ExchangeMoney wallet=${props.usdTable ? "'USD'" : "'CHP'"}><Sources><ExchangeSource wallet=${!props.usdTable ? "'USD'" : "'CHP'"} cash="${inputValues['cash']}" /></Sources></ExchangeMoney>`);
      }
      if (Number(inputValues['bonus']) > 0) {
        setTimeout(() => {
          props.network.send(`<ExchangeMoney wallet=${props.usdTable ? "'USD'" : "'CHP'"}><Sources><ExchangeSource wallet=${!props.usdTable ? "'USD'" : "'CHP'"} bonus="${inputValues['bonus']}" /></Sources></ExchangeMoney>`);
        }, 100);
      }
      setNewAvlBal((Number(props.onMoneyExchangeInfo.attr.cash) + (Number(inputValues['cash']) + (Number(inputValues['bonus'])))))
      // props.network.send(`<CancelBuyChips />`);
      setPausTheTime(true);
      console.log(props.onMoneyExchangeInfo.attr.cash)
      if (Number(props.data.min) <= (Number(props.onMoneyExchangeInfo.attr.cash) + (Number(inputValues['cash']) + (Number(inputValues['bonus']))))) {
        setTimeout(() => {
          props.onGetTakeSeat("", props.data.time > 0 ? "take_seat" : "rebuy_chips");
        }, 1000);
      }
      inputValues['cash'] = 0;
      inputValues['bonus'] = 0;
      setHeader(`Add Chips - ${props.usdTable ? "USD" : "CHP"}`);
    }
  }


  const onClickCancel = () => {
    props.network.send(`<CancelBuyChips />`)
    props.setAction("hideBuyChips1");
  };

  // const onChangeSlider = (e) => {
  //   let sv = document.getElementById("sliderBar");
  //   // console.log(sv.value)
  //   setAmount(sv.value);

  //   const sliderWrapper = document.querySelector('.slider-wrapper');
  //   const sliderInput = document.querySelector('.slider-input');
  //   const sliderThumb = document.querySelector('.slider-thumb');


  //   const maxValue = +sliderInput.max || 0;

  //   const colorStops = [
  //     { r: 98, g: 173, b: 29 },
  //     { r: 251, g: 189, b: 30 },
  //     { r: 251, g: 110, b: 30 },
  //     { r: 231, g: 26, b: 26 },
  //   ];

  //   const updateSlider = () => {
  //     // var x = 100 / e.target.max
  //     const progress = 100 * (e.target.value - e.target.min) / (e.target.max - e.target.min)
  //     // const progress = ((e.target.value) * x);
  //     sliderWrapper.style.setProperty('--slider-progress', progress);
  //   }

  //   sliderInput.addEventListener('input', updateSlider);

  //   updateSlider();

  //   let pointerHoldingRightTimer;
  //   let isTimerRunning = false;

  //   const detectPointerHoldingRight = (event) => {
  //     const isSliderAtMax = +sliderInput.value === maxValue;

  //     const sliderRect = sliderWrapper.getBoundingClientRect();
  //     const pointerX = event.clientX;
  //     const offset = 40;
  //     const isPointerOnSliderRight = pointerX >= (sliderRect.right + offset);

  //     if (isSliderAtMax && isPointerOnSliderRight) {
  //       if (!isTimerRunning) {
  //         isTimerRunning = true;
  //         pointerHoldingRightTimer = setTimeout(() => {
  //           sliderInput.removeEventListener('pointerdown', detectSecret);
  //           colorStops.push({ r: 34, g: 0, b: 0 });
  //           sliderWrapper.classList.add('unlocked');
  //           sliderThumb.addEventListener('animationend', () => {
  //             sliderThumb.classList.remove('play-explosion');
  //           });
  //           sliderThumb.classList.add('play-explosion');
  //         }, 1000);
  //       }
  //     } else {
  //       clearTimeout(pointerHoldingRightTimer);
  //       isTimerRunning = false;
  //     }
  //   };

  //   const detectSecret = () => {
  //     window.addEventListener('pointermove', detectPointerHoldingRight);
  //     sliderInput.addEventListener('pointerup', () => {
  //       window.removeEventListener('pointermove', detectPointerHoldingRight);
  //       clearTimeout(pointerHoldingRightTimer);
  //     });
  //   }
  //   sliderInput.addEventListener('pointerdown', detectSecret);
  // };
  // const onChangeInputNumber = () => {
  //   let tv = document.getElementById("textInput");
  //   console.log(tv.value)
  //   setAmount(tv.value);

  //   const sliderWrapper = document.querySelector('.slider-wrapper');
  //   if (Number(max) <= Number(tv.value)) {
  //     var x = 100 / max
  //     const progress = ((max) * x);
  //     sliderWrapper.style.setProperty('--slider-progress', progress);
  //     setAmount(max);
  //   } else if (Number(tv.value) === 0) {
  //     setAmount(0);
  //   } else {
  //     var x = 100 / max
  //     const progress = ((tv.value) * x);
  //     sliderWrapper.style.setProperty('--slider-progress', progress);
  //     setAmount(tv.value);
  //   }
  //   setTimeout(() => {
  //     if (Number(min) >= Number(tv.value)) {
  //       var x = 100 / max
  //       const progress = ((min) * x);
  //       sliderWrapper.style.setProperty('--slider-progress', progress);
  //       setAmount(min);
  //     }
  //   }, 9000);

  // };


  // const onTestSlider = (e) => {
  //   updateGradient(e.target.value)
  // }
  // const updateGradient = (data) => {

  //   const rangeInput = document.getElementById('myRange1');
  //   const minValue = parseFloat(rangeInput.min);
  //   const maxValue = parseFloat(rangeInput.max);
  //   const currentValue = data;
  //   const percentage = (currentValue - minValue) / (maxValue - minValue) * 100;
  //   // console.log(percentage)
  //   let gradient = 'linear-gradient(to right, #c38f03 0%, #c38f03 ' + percentage + '%, #d3d3d3 ' + percentage + '%, #d3d3d3 100%)';
  //   rangeInput.style.background = gradient;
  //   setAmount(data)
  // }


  const onTestSlider = (e) => {
    const value = parseFloat(e.target.value).toFixed(2);
    updateGradient(value);
  };

  const updateGradient = (data) => {
    const rangeInput = document.getElementById('myRange1');
    const minValue = parseFloat(rangeInput.min).toFixed(2);
    const maxValue = parseFloat(rangeInput.max).toFixed(2);
    const currentValue = parseFloat(data).toFixed(2);
    const percentage = ((currentValue - minValue) / (maxValue - minValue)) * 100;

    let gradient = `linear-gradient(to right, #c38f03 0%, #c38f03 ${percentage}%, #d3d3d3 ${percentage}%, #d3d3d3 100%)`;
    rangeInput.style.background = gradient;
    setAmount(parseFloat(currentValue));
  };


  // useEffect(() => {
  //   updateGradient(amount);
  // }, [amount]);


  const COLOR_CODES = {
    info: {
      color: "green"
    },
    warning: {
      color: "orange",
      threshold: 10
    },
    alert: {
      color: "red",
      threshold: 5
    }
  };
  const COLOR_CODES_lB = {
    info: {
      color: "red"
    },
    warning: {
      color: "red",
      threshold: 10
    },
    alert: {
      color: "red",
      threshold: 5
    }
  };
  const TIME_LIMIT = time;
  let timePassed = 0;
  let timeLeft = TIME_LIMIT;

  useEffect(() => {
    let timerInterval;
    if (fileName.name === "Leader_bet") {
      startTimer();

      function onTimesUp() {
        clearInterval(timerInterval);
      }

      function startTimer() {
        timerInterval = setInterval(() => {
          timePassed += 1;
          timeLeft = TIME_LIMIT - timePassed;
          if (Screen.getDeviceType().name !== "Mobile") {
            document.getElementById("base-timer-label").innerHTML = formatTime(timeLeft);
          }
          setCircleDasharray();
          setRemainingPathColor(timeLeft);
          if (timeLeft === 0) {
            onTimesUp();
          }
        }, 1000);
      }

      function formatTime(time) {
        const minutes = Math.floor(time / 60);
        let seconds = time % 60;
        seconds = seconds < 10 ? `0${seconds}` : seconds;
        return `${minutes}:${seconds}`;
      }

      var remainingPathColor = (Screen.getDeviceType().name !== "Mobile" ? COLOR_CODES.info.color : COLOR_CODES_lB.info.color);
      // var remainingPathColor = COLOR_CODES.info.color;
      const pathElement = document.getElementById("base-timer-path-remaining");
      pathElement.classList.add(remainingPathColor);
      function setRemainingPathColor(timeLeft) {
        const { alert, warning, info } = COLOR_CODES;
        if (timeLeft <= alert.threshold) {
          pathElement.classList.remove(warning.color);
          pathElement.classList.add(alert.color);
        } else if (timeLeft <= warning.threshold) {
          pathElement.classList.remove(info.color);
          pathElement.classList.add(warning.color);
        }
      }

      function calculateTimeFraction() {
        const rawTimeFraction = timeLeft / TIME_LIMIT;
        return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
      }
      let num = (Screen.getDeviceType().name !== "Mobile" ? 283 : 100);
      function setCircleDasharray() {
        // const circleDasharray = `${(calculateTimeFraction() * 283).toFixed(0)} 283`;
        const circleDasharray = `${(calculateTimeFraction() * num).toFixed(0)} 283`;
        pathElement.setAttribute("stroke-dasharray", circleDasharray);
      }

      return () => clearInterval(timerInterval);
    }
  }, [props.data.time]);


  // const setMaxAmount = () => {
  //   var slider = document.getElementById("myRange1");
  //   setAmount(slider.max)
  //   slider.style.background = '#3EB553';
  // }

  // const calculateChips = (e) => {
  //   var slider = document.getElementById("myRange1");
  //   var presentAmount = parseFloat(slider.value);
  //   var twentyPercent = presentAmount * 0.20;
  //   if (e.target.value == '+') {
  //     if (Number(presentAmount) + Number(twentyPercent) > Number(slider.max)) {
  //       var newAmount = Number(slider.max);
  //       setAmount(slider.max)
  //     } else {
  //       var newAmount = presentAmount + twentyPercent;
  //       setAmount(newAmount)
  //     }
  //   } else {
  //     if (Number(presentAmount) - Number(twentyPercent) < Number(slider.min)) {
  //       var newAmount = 0;
  //       setAmount(slider.min)
  //     } else {
  //       var newAmount = presentAmount - twentyPercent;
  //       setAmount(newAmount)
  //     }
  //   }

  //   var value = (newAmount - min) / (max - min) * 100;
  //   slider.style.background = 'linear-gradient(to right, #4CAF50 0%, #4CAF50 ' + value + '%, #d3d3d3 ' + value + '%, #d3d3d3 100%)';
  // }


  const [inputFocus, setInputFocus] = useState({ cash: false, bonus: false });

  const handleInputChange = (e, attrKey) => {
    const newValue = Number(e.target.value);
    const { usdTable, onMoneyExchangeInfo } = props;
    const { cash, bonus } = onMoneyExchangeInfo.Balance.attr;
    const chpToUsdRate = onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate;
    const usdToChpRate = 1 / chpToUsdRate;

    const totalAmountCash = usdTable ? cash * chpToUsdRate : cash * usdToChpRate;
    const totalAmountBonus = usdTable ? bonus * chpToUsdRate : bonus * usdToChpRate;

    const updateState = (amount, totalAmount, currency) => {
      if (newValue <= totalAmount) {
        setErrorMsgText("");
        setInputValues(prevState => ({
          ...prevState,
          [attrKey]: e.target.value
        }));
      } else {
        setErrorMsgText(`You can convert a maximum of ${UM.numberWithCommas(totalAmount.toFixed(2))} ${currency} only.`);
      }
    };

    if (attrKey === "cash") {
      updateState(newValue, totalAmountCash, usdTable ? "USD" : "CHP");
    } else if (attrKey === "bonus") {
      updateState(newValue, totalAmountBonus, usdTable ? "USD" : "CHP");
    }
  };

  const handleInputFocus = (attrKey) => {
    setInputFocus(prevState => ({
      ...prevState,
      [attrKey]: true
    }));
  };

  const handleInputBlur = (attrKey) => {
    setInputFocus(prevState => ({
      ...prevState,
      [attrKey]: false
    }));

    if (inputValues[attrKey] === "" || inputValues[attrKey] === undefined) {
      setInputValues(prevState => ({
        ...prevState,
        [attrKey]: 0
      }));
    }
  };



  return (
    <React.Fragment>
      {header !== `Add Chips - ${props.usdTable ? "USD" : "CHP"}` ?
        <div className="popCover_1" >  </div> :
        <div className="popCover_1" onClick={onClickCancel}>  </div>
      }

      {(Number(min) > Number(newAvlBal)) ?

        <div className="popup_1" style={{ maxWidth: 'fit-content' }}>
          <div className="popup_1_in" style={{ minHeight: '170px' }}>
            <div className="head">
              <span className="settingsSpan">
                <img src={dollar} alt="" style={{ display: fileName.name === "Leader_bet" ? 'none' : 'block' }} /> {header}</span>
              <button className="close_1" onClick={onClickCancel}> <img src={close_1} alt="" />  </button>
            </div>
            {errorMsg && (
              <div className="errorMsgDiv">
                <span>{errorMsgText}</span>
              </div>
            )}
            {header !== `Add Chips - ${props.usdTable ? "USD" : "CHP"}` ?
              // <div div className="fd exChangeDiv">
              //   <p>Exchange funds to {props.usdTable ? "USD" : "CHP"} </p>
              //   {(props.onMoneyExchangeInfo && props.onMoneyExchangeInfo.hasOwnProperty("attr")) &&
              //     <>
              //       <table>
              //         <thead>
              //           <tr>
              //             <td>Wallet</td>
              //             <td>Available</td>
              //             <td>Rate</td>
              //             <td>Amount to Receive in {props.usdTable ? "USD" : "CHP"}</td>
              //           </tr>
              //         </thead>
              //         <tbody>
              //           <tr>
              //             <td>{props.usdTable ? "CHP" : "USD"}  Cash</td>
              //             <td>{UM.numberWithCommas(props.onMoneyExchangeInfo.Balance.attr.cash)}</td>
              //             <td>{UM.numberWithCommas(props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate)}</td>
              //             <td>
              //               <span>
              //                 <input type="number" value={inputValues['cash']} onChange={(e) => handleInputChange(e, 'cash')} />
              //               </span>
              //               <span>
              //                 {!props.usdTable ? "CHP" : "USD"} ( {props.usdTable ? UM.numberWithCommas((inputValues['cash'] / props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate).toFixed(2)) :
              //                   UM.numberWithCommas((inputValues['cash'] * props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate).toFixed(2))
              //                 })
              //               </span>
              //             </td>
              //           </tr>

              //           <tr>
              //             <td>{props.usdTable ? "CHP" : "USD"} Bonus</td>
              //             <td>{UM.numberWithCommas(props.onMoneyExchangeInfo.Balance.attr.bonus)}</td>
              //             <td>{UM.numberWithCommas(props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate)}</td>
              //             <td>
              //               <span>
              //                 <input type="number" value={inputValues['bonus']} onChange={(e) => handleInputChange(e, 'bonus')} />
              //               </span>
              //               <span>
              //                 {!props.usdTable ? "CHP" : "USD"} ({props.usdTable ? UM.numberWithCommas((inputValues['bonus'] / props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate).toFixed(2)) :
              //                   UM.numberWithCommas((inputValues['bonus'] * props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate).toFixed(2))
              //                 })
              //               </span>
              //             </td>
              //           </tr>

              //         </tbody>
              //       </table>
              //       <div className="df">
              //         <h4>Summary:</h4>
              //         <div className="m_5">
              //           <p>
              //             <span>Cash :</span>
              //             <span> {props.usdTable ? "$" : ""}  {UM.numberWithCommas(inputValues['cash'])}</span>
              //           </p>
              //           <p>
              //             <span>Bonus :</span>
              //             <span> {props.usdTable ? "$" : ""} {UM.numberWithCommas(inputValues['bonus'])}</span>
              //           </p>
              //         </div>
              //       </div>

              //     </>
              //   }
              // </div>

              <div className="fd exChangeDiv">
                <p>Exchange funds to {props.usdTable ? "USD" : "CHP"}</p>
                {props.onMoneyExchangeInfo && props.onMoneyExchangeInfo.hasOwnProperty("attr") && (
                  <>
                    <table>
                      <thead>
                        <tr>
                          <th>Wallet</th>
                          <th>Available</th>
                          <th>Rate</th>
                          <th>Amount to Receive in {props.usdTable ? "USD" : "CHP"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{props.usdTable ? "CHP" : "USD"} Cash</td>
                          <td>{UM.numberWithCommas(props.onMoneyExchangeInfo.Balance.attr.cash)}</td>
                          {props?.onMoneyExchangeInfo?.CurrencyRates?.CurrencyRate?.attr?.rate >= 1 ?
                            <td>{UM.numberWithCommas(props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate)}</td> :
                            <td>{props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate}</td>
                          }
                          <td>
                            <span>
                              <input
                                type="number"
                                value={
                                  inputFocus.cash && inputValues['cash'] === 0 ? "" : inputValues['cash']
                                }
                                onChange={(e) => handleInputChange(e, 'cash')}
                                onFocus={() => handleInputFocus('cash')}
                                onBlur={() => handleInputBlur('cash')}
                              />
                            </span>
                            <span>
                              {!props.usdTable ? "CHP" : "USD"} (
                              {props.usdTable
                                ? UM.numberWithCommas((inputValues['cash'] / props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate).toFixed(2))
                                : UM.numberWithCommas((inputValues['cash'] * props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate).toFixed(2))
                              })
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td>{props.usdTable ? "CHP" : "USD"} Bonus</td>
                          <td>{UM.numberWithCommas(props.onMoneyExchangeInfo.Balance.attr.bonus)}</td>
                          <td>{UM.numberWithCommas(props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate)}</td>
                          <td>
                            <span>
                              <input
                                type="number"
                                id="inputBonus"
                                value={
                                  inputFocus.bonus && inputValues['bonus'] === 0 ? "" : inputValues['bonus']
                                }
                                onChange={(e) => handleInputChange(e, 'bonus')}
                                onFocus={() => handleInputFocus('bonus')}
                                onBlur={() => handleInputBlur('bonus')}
                              />
                            </span>
                            <span>
                              {!props.usdTable ? "CHP" : "USD"} (
                              {props.usdTable
                                ? UM.numberWithCommas((inputValues['bonus'] / props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate).toFixed(2))
                                : UM.numberWithCommas((inputValues['bonus'] * props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate).toFixed(2))
                              })
                            </span>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                    <div className="df">
                      <h4>Summary:</h4>
                      <div className="m_5">
                        <p>
                          <span>Cash :</span>
                          <span> {props.usdTable ? "$" : ""} {UM.numberWithCommas(inputValues['cash'])}</span>
                        </p>
                        <p>
                          <span>Bonus :</span>
                          <span> {props.usdTable ? "$" : ""} {UM.numberWithCommas(inputValues['bonus'])}</span>
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>

              :
              <div className="fd buyNew" style={{ width: '100%', padding: '15px' }}>
                <div className="fd buyBalance" style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="df">
                    <span style={{ margin: '5px' }}>{props.t("Your current CHP balance")}: </span>
                    <span style={{ margin: '1px' }} className="clr_ff font_20 bold"> {UM.numberWithCommas(props.playerBalance["myCHPbalance"])} </span>
                  </div>
                  <div className="df">
                    <span style={{ margin: '5px' }}>{props.t("Your current USD balance")}: </span>
                    <span style={{ margin: '1px' }} className="clr_ff font_20 bold"> {UM.numberWithCommas(props.playerBalance["myUSDbalance"])} </span>
                  </div>
                </div>
                <div className="fd">
                  <div className="minMaxBalance" style={{ flexDirection: 'row', justifyContent: 'center', margin: '5px' }}>
                    <span > {props.t("Minimum buy-in")} : </span>
                    <span className="clr_4 font_15"> {UM.numberWithCommas(min)} </span>
                  </div>
                </div>
              </div>
            }
            <div className="exchangeBtns" >
              <button className="btn_2" onClick={onClickCancel}> Cancel  </button>
              {/* {(showTimer && header === `Add Chips - ${props.usdTable ? "USD" : "CHP"}`) && ( */}
              <div className="df_al_js-center" style={{ visibility: (props.data.time > 0 && pausTheTime) ? 'visible' : 'hidden' }}>
                {props.t("Time Left")}: <span style={{ color: "red" }} className="font_18">{time}</span>
              </div>
              {/* )} */}
              <button className="btn_2 fd" onClick={onClickExchange} disabled={(Number(inputValues['cash']) <= 0 && Number(inputValues['bonus']) <= 0) && header !== `Add Chips - ${props.usdTable ? "USD" : "CHP"}`}>Exchange</button>
            </div>
          </div>
        </div>

        :

        <div className={fileName.name === "Leader_bet" ? "popup_1_lb" : "popup_1"} style={{ maxWidth: (Screen.getDeviceType().name === "Mobile" && fileName.name === "Leader_bet") ? "307px" : fileName.name === "Leader_bet" ? "545px" : "" }}>
          <div className={fileName.name === "Leader_bet" ? "popup_1_in_lb" : "popup_1_in"} style={{ minHeight: '170px' }}>
            <div className="head">
              <span className="settingsSpan">
                <div className="sprite" style={{ backgroundPositionY: "81px" }}></div> {props.t("Add Chips - CHP")}</span>

            </div>
            {errorMsg && (
              <div className="errorMsgDiv">
                <span>{errorMsgText}</span>
              </div>
            )}

            <>
              <div className="fd" style={{ borderBottom: "1px solid #000", padding: "10px 15px" }}>
                <span>How much money would you like to bring to this table</span>
              </div>
              <div className="fd buyNew">
                <div className="fd buyBalance p_5">
                  <span >{props.t("Your current balance")}: <span className="clr_ff bold"> {UM.changeAmtLabel(newAvlBal)} </span></span>
                </div>
                <div className="fd">
                  <table className="fd">
                    <tbody>
                      <tr className="v_align">
                        <td>
                          <div className="minMaxBalance p_5">
                            <span > {props.t("Minimum buy-in")} : <span className="clr_4 font_15"> {UM.changeAmtLabel(newMinBal)} </span></span>
                          </div>
                        </td>
                        <td>
                          <div className="minMaxBalance p_5">
                            <span > {props.t("Maximum buy-in")} : <span className="clr_4 font_15"> {UM.changeAmtLabel(newMaxBal)} </span></span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>


              <div className="slider-container fd row">
                <input
                  type="range"
                  min={min}
                  max={max}
                  step="0.01"
                  value={amount}
                  className="custom-slider"
                  id="myRange1"
                  onChange={onTestSlider}
                  style={{ appearance: 'none', width: '70%', height: '10px', transition: 'opacity 0.2s', border: 'none', marginTop: '-5px', borderRadius: '20px', margin: '15px' }}
                />


                <input id="textInput" className="inputValue" style={{ width: '20%' }} type="text" value={UM.changeAmtLabel(amount)} disabled={btnState} readOnly />
              </div>
              <div className="fd p_5 m_t_15" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: "#1e1e1e30" }}>
                <button className="btn_2 fd" onClick={onClickBuy}  >{props.t("OK")} </button>
                <div className="df_al_js-center" style={{ visibility: (props.data.time > 0 && pausTheTime) ? 'visible' : 'hidden' }}>
                  {props.t("Time Left")}: <span style={{ color: "red" }} className="font_18">{time}</span>
                </div>
                <button className="btn_2 fd" onClick={onClickClose}  >{props.t("close")} </button>
              </div>
            </>
          </div>
        </div>
      }
    </React.Fragment >
  );
}
export default withTranslation()(BuyChips);
