

import React, { useImperativeHandle, useState, useEffect } from "react";
export const Breaktime = React.forwardRef((props, ref) => {

  const stylevisiable = {
    position: 'fixed',
    // left: `calc(30% - ${(window.innerWidth-880)/2}px)`,
    left: "30%",
    top: `calc(50% - 45px)`,
    width: "40%",
    visibility: "visible"

    // You can add other styles here
  };
  const stylehidden = {
    position: 'fixed',
    // left: `calc(30% - ${(window.innerWidth-880)/2}px)`,
    left: "30%",
    top: `calc(50% - 45px)`,
    width: "40%",
    visibility: "hidden"

    // You can add other styles here
  };

  const [time, setTime] = useState(0);
  useImperativeHandle(ref, () => ({
    childMethod(e) {
      childMethod(e)
      // setTime(Number(e))
    }
  }))

  function childMethod(e) {
    // alert("call me-->>>>"+e)


    setTime(Number(e))
  }
  useEffect(() => {

    let timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);

    // console.log(`Tournament is on break.Next hand will start in: ${`${Math.floor(time / 60)}`.padStart(2, 0)} minutes:
    // ${`${time % 60}`.padStart(2, 0)} seconds`)
    return () => { clearInterval(timer); }
  }, []);

  // return (<View><Text> I am a child</Text></View>)
  return (



    <div className="head breakTimePopup" style={!props.setCondition ? stylevisiable : stylehidden}>
      <div className="settingsSpan sprite1_child" >
        <div className="sprite1" style={{ backgroundPositionY: "47px" }} ></div>

        <p style={{ zIndex: "1" }}>
          {`${"    "}`} Break...Next level will Start in: {`${Math.floor(time / 60)}`.padStart(2, 0)} m:
          {`${time % 60}`.padStart(2, 0)} s
        </p>
      </div>
    </div>

  )
})
