const Columns = [
  {
    Header: "Name",
    accessor: "name",
  },

  // {
  //   Header: "Game",
  //   accessor: "game",
  // },

  // {
  //   Header: "BuyIn",
  //   accessor: "buyIn",
  // },
  // {
  //   Header: "Players",
  //   accessor: "players",
  // },
  // {
  //   Header: "Status",
  //   accessor: "status",
  // }
]
const UM = { Columns };
export default UM;