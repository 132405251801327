import React, { useMemo, useState, useEffect, useRef } from "react";
import "../../../../../css/ui/lobby/mainGrid.css";
import Columns from "./mainGridColumns";
import { useTable, useRowSelect, useSortBy } from "react-table";
import { withTranslation } from 'react-i18next';
import fileName from "../../../../../jsconfig";
import Config from "../../../../../config";

export const MainGrid = (props) => {
    const config = new Config();
    const columns = useMemo(() => Columns.Columns1, [fileName.name]);
    const data = useMemo(() => props.data, [props.data]);

    const scrollContainerRef = useRef(null);

    const table = useTable(
        { columns, data, initialState: {} },
        useSortBy,
        useRowSelect
    );

    const {
        getTableProps,
        getTableBodyProps,
        rows,
        prepareRow,
    } = table;

    const [prevRow, setPrevRow] = useState(null);

    // useEffect(() => {
    //     if (scrollContainerRef.current) {
    //         scrollContainerRef.current.scrollTo({
    //             top: 0,
    //             left: 0,
    //             behavior: 'smooth'
    //         });
    //     }
    // }, [props.data]);

    const [singleClick, setSingleClick] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width > 1200) {
                setSingleClick(false);
            } else {
                setSingleClick(true);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const rows = document.getElementsByTagName("tr");
        Array.from(rows).forEach(row => {
            const id = row.dataset.id;
            const type = row.dataset.type;
            const isSeated = props.mytables.some(table => row.innerText.includes(table.name));
            row.classList.toggle("seated", isSeated);
            if (type === "SINGLE_TABLE") {
                row.onclick = () => {
                    // if (row.innerText.includes("Seats")) return;
                    if (prevRow) {
                        prevRow.style.borderBottom = null;
                    }

                    if (id) {
                        props.network.send(`<GetTableDetails id="${id}" type="${type}"/>`);
                        if (singleClick) {
                            props.network.send(`<OpenTable id="${id}" type="${type}"/>`);
                            props.setAction("mainGridDblClick");
                        }
                        row.style.borderBottom = "1px solid #3ba14c";
                    }
                    setPrevRow(row);
                    if (!singleClick) {
                        props.setOpenAction("openSideMiniTable");
                        row.ondblclick = () => {
                            if (id) {
                                props.network.send(`<OpenTable id="${id}" type="${type}"/>`);
                                props.setAction("mainGridDblClick");
                            }
                        }
                    }
                }
            };
        });

        return () => {
            Array.from(rows).forEach(row => {
                row.onclick = null;
            });
        };
    }, [prevRow, props.mytables, props.network, props.setAction, fileName.name]);

    const openDropDown = (e) => {
        const tabs = ["table_filter", "stakes_filter", "seats_filter"];
        tabs.forEach((id) => {
            const element = document.getElementById(id);
            if (!element) return;

            const game_type_filter = document.getElementById("game_type_filter");
            const sort_fliter = document.getElementById("Stake_Sort_Fliter");
            const sort_fliter1 = document.getElementById("Seats_Sort_Fliter");

            if (element.id === e.target.name) {
                if (element.classList.contains("rotate_smooth")) {
                    element.classList.remove('rotate_smooth');
                } else {
                    if (element.id === "table_filter" && game_type_filter) {
                        game_type_filter.style.display = 'flex';
                    } else if (element.id === "seats_filter" && sort_fliter1) {
                        sort_fliter1.style.display = 'flex';
                    } else if (element.id === "stakes_filter" && sort_fliter) {
                        sort_fliter.style.display = 'flex'
                    }
                    element.classList.add('rotate_smooth');
                }
            } else {
                element.classList.remove('rotate_smooth');
            }
        });
    };


    return (
        <div className="mainGrid mainGridOverflow" ref={scrollContainerRef}>
            <table id="mainGrid_table_rv" {...getTableProps()}>
                <thead>
                    <div className="df table_header_row" style={{ width: '100%', alignItems: 'center' }}>
                        {/* <div className="df col-12" style={{ color: '#ffff', padding: '0px 0px 0px 15px' }}>
                            <span style={{ width: '75px' }}></span>
                            <span style={{ width: '32%' }} >TABLE NAME</span>
                            <button className="table_type_dropdown" style={{ width: "15.8%" }} name="table_filter">GAME</button>
                            <button className="table_type_dropdown df_al" style={{ width: "15.8%" }} name="stakes_filter" onClick={(e) => openDropDown(e)}>STAKES<span className="m_l_20" id="stakes_filter">
                          
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b79301">
                                    <path d="M480-328 225-583h510L480-328Z" />
                                </svg>
                            </span> </button>
                            <button className="table_type_dropdown df_al" style={{ width: "15.8%" }} name="seats_filter" onClick={(e) => openDropDown(e)}>SEATS<span className="m_l_20" id="seats_filter">
                        
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b79301">
                                    <path d="M480-328 225-583h510L480-328Z" />
                                </svg>
                            </span></button>
                            <span style={{ width: "15.8%" }}>AVG.POT / PLAYERS / ROUNDS</span>
                        </div> */}

                        <span style={{ marginRight: '75px' }}></span>
                        <div className="df row" style={{ color: '#ffff', padding: '0px 0px 0px 15px' }}>
                            <span className="col-lg-3 col-xl-3 bold" >Name</span>
                            <button className="table_type_dropdown col-lg-2 col-xl-2 bold" name="table_filter">Game</button>
                            <button className="table_type_dropdown col-lg-2 col-xl-2 df_al bold" name="stakes_filter" onClick={(e) => openDropDown(e)}>
                                Stakes
                                <span className="m_l_20" id="stakes_filter" >
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b79301">
                                        <path d="M480-328 225-583h510L480-328Z" />
                                    </svg>
                                </span>
                            </button>
                            <button className="table_type_dropdown col-lg-2 col-xl-2 df_al bold" name="seats_filter" onClick={(e) => openDropDown(e)}>
                                Plrs.
                                <span className="m_l_20" id="seats_filter" >
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b79301">
                                        <path d="M480-328 225-583h510L480-328Z" />
                                    </svg>
                                </span>
                            </button>
                            <span className="col-lg-1 col-xl-1 bold">Avg.Pot</span>
                            <span className="col-lg-1 col-xl-1 bold">P/F</span>
                            <span className="col-lg-1 col-xl-1 bold">H/hr</span>
                        </div>
                    </div>
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        if (row.original.name !== "No Table Matching Your Search Criteria") {
                            return (
                                <tr
                                    key={row.original.id}
                                    data-id={row.original.id}
                                    data-type={row.original.type}
                                    data-password={row.original.password}
                                    data-runtwice={row.original.runtwice}
                                    data-jackpot={row.original.jackpot}
                                    {...row.getRowProps()}
                                >
                                    {row.cells.map((cell, i) => (
                                        <td key={i} {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                        </td>
                                    ))}
                                </tr>
                            );
                        } else {
                            return (
                                <tr key="NOtables">
                                    <td colSpan={columns.length} style={{ width: '100%', textAlign: 'center' }}>
                                        {row.original.name}
                                    </td>
                                </tr>
                            );
                        }
                    })}
                </tbody>
            </table>
        </div >
    );
};

export default withTranslation()(MainGrid);
