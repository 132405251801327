import React, { useEffect, useState } from "react";
import "../../../css/ui/lobby/tableDetails/tablePreview.css";
// import '../../../../../css/ui/lobby/tableDetails/tablePreview.css';
// import UM from "../../../../utils/utilityMethods";
import table from "../../../assets/images/tableandcarpet/bg_table.png";
import UM from "../../utils/utilityMethods";

export default function TablePreview(props) {
	// console.log(props);
	const seatPostion = {
		2: [
			// {
			// 	position: "absolute",
			// 	left: "calc(70.5% - 55px)",
			// 	top: "15%",
			// },
			// {
			// 	position: "absolute",
			// 	left: "calc(91% - 55px)",
			// 	top: "26%",
			// },
			{
				position: "absolute",
				left: "calc(93% - 55px)",
				top: "43.25%",
			},
			// {
			// 	position: "absolute",
			// 	left: "calc(91% - 55px)",
			// 	top: "60.5%",
			// },
			// {
			// 	position: "absolute",
			// 	left: "calc(70.5% - 55px)",
			// 	top: "71%",
			// },
			// {
			// 	position: "absolute",
			// 	left: "calc(38% - 55px)",
			// 	top: "71%",
			// },
			// {
			// 	position: "absolute",
			// 	left: "calc(17% - 55px)",
			// 	top: "60.5%",
			// },
			{
				position: "absolute",
				left: "calc(15% - 55px)",
				top: "43.25%",
			},
			// {
			// 	position: "absolute",
			// 	left: "calc(17% - 55px)",
			// 	top: "26%",
			// },
			// {
			// 	position: "absolute",
			// 	left: "calc(38% - 55px)",
			// 	top: "15%",
			// },
		],
		3: [
			// {
			// 	position: "absolute",
			// 	left: "calc(70.5% - 55px)",
			// 	top: "15%",
			// },
			// {
			// 	position: "absolute",
			// 	left: "calc(91% - 55px)",
			// 	top: "26%",
			// },
			{
				position: "absolute",
				left: "calc(93% - 55px)",
				top: "43.25%",
			},
			// {
			// 	position: "absolute",
			// 	left: "calc(91% - 55px)",
			// 	top: "60.5%",
			// },
			// {
			// 	position: "absolute",
			// 	left: "calc(70.5% - 55px)",
			// 	top: "71%",
			// },
			// {
			// 	position: "absolute",
			// 	left: "calc(38% - 55px)",
			// 	top: "71%",
			// },
			{
				position: "absolute",
				left: "calc(17% - 55px)",
				top: "60.5%",
			},
			// {
			// 	position: "absolute",
			// 	left: "calc(15% - 55px)",
			// 	top: "43.25%",
			// },
			{
				position: "absolute",
				left: "calc(17% - 55px)",
				top: "26%",
			},
			// {
			// 	position: "absolute",
			// 	left: "calc(38% - 55px)",
			// 	top: "15%",
			// },
		],
		4: [
			{
				position: "absolute",
				left: "calc(70.5% - 55px)",
				top: "15%",
			},
			// {
			// 	position: "absolute",
			// 	left: "calc(91% - 55px)",
			// 	top: "26%",
			// },
			// {
			// 	position: "absolute",
			// 	left: "calc(93% - 55px)",
			// 	top: "43.25%",
			// },
			// {
			// 	position: "absolute",
			// 	left: "calc(91% - 55px)",
			// 	top: "60.5%",
			// },
			{
				position: "absolute",
				left: "calc(70.5% - 55px)",
				top: "71%",
			},
			{
				position: "absolute",
				left: "calc(38% - 55px)",
				top: "71%",
			},
			// {
			// 	position: "absolute",
			// 	left: "calc(17% - 55px)",
			// 	top: "60.5%",
			// },
			// {
			// 	position: "absolute",
			// 	left: "calc(15% - 55px)",
			// 	top: "43.25%",
			// },
			// {
			// 	position: "absolute",
			// 	left: "calc(17% - 55px)",
			// 	top: "26%",
			// },
			{
				position: "absolute",
				left: "calc(38% - 55px)",
				top: "15%",
			},
		],
		5: [
			{
				position: "absolute",
				left: "calc(70.5% - 55px)",
				top: "15%",
			},
			// {
			// 	position: "absolute",
			// 	left: "calc(91% - 55px)",
			// 	top: "26%",
			// },
			{
				position: "absolute",
				left: "calc(93% - 55px)",
				top: "43.25%",
			},
			// {
			// 	position: "absolute",
			// 	left: "calc(91% - 55px)",
			// 	top: "60.5%",
			// },
			{
				position: "absolute",
				left: "calc(70.5% - 55px)",
				top: "71%",
			},
			{
				position: "absolute",
				left: "calc(38% - 55px)",
				top: "71%",
			},
			// {
			// 	position: "absolute",
			// 	left: "calc(17% - 55px)",
			// 	top: "60.5%",
			// },
			// {
			// 	position: "absolute",
			// 	left: "calc(15% - 55px)",
			// 	top: "43.25%",
			// },
			// {
			// 	position: "absolute",
			// 	left: "calc(17% - 55px)",
			// 	top: "26%",
			// },
			{
				position: "absolute",
				left: "calc(38% - 55px)",
				top: "15%",
			},
		],
		6: [
			{
				position: "absolute",
				left: "calc(70.5% - 55px)",
				top: "15%",
			},
			// {
			// 	position: "absolute",
			// 	left: "calc(91% - 55px)",
			// 	top: "26%",
			// },
			{
				position: "absolute",
				left: "calc(93% - 55px)",
				top: "43.25%",
			},
			// {
			// 	position: "absolute",
			// 	left: "calc(91% - 55px)",
			// 	top: "60.5%",
			// },
			{
				position: "absolute",
				left: "calc(70.5% - 55px)",
				top: "71%",
			},
			{
				position: "absolute",
				left: "calc(38% - 55px)",
				top: "71%",
			},
			// {
			// 	position: "absolute",
			// 	left: "calc(17% - 55px)",
			// 	top: "60.5%",
			// },
			{
				position: "absolute",
				left: "calc(15% - 55px)",
				top: "43.25%",
			},
			// {
			// 	position: "absolute",
			// 	left: "calc(17% - 55px)",
			// 	top: "26%",
			// },
			{
				position: "absolute",
				left: "calc(38% - 55px)",
				top: "15%",
			},
		],
		7: [
			{
				position: "absolute",
				left: "calc(70.5% - 55px)",
				top: "15%",
			},
			{
				position: "absolute",
				left: "calc(91% - 55px)",
				top: "26%",
			},
			// {
			// 	position: "absolute",
			// 	left: "calc(93% - 55px)",
			// 	top: "43.25%",
			// },
			{
				position: "absolute",
				left: "calc(91% - 55px)",
				top: "60.5%",
			},
			{
				position: "absolute",
				left: "calc(70.5% - 55px)",
				top: "71%",
			},
			{
				position: "absolute",
				left: "calc(38% - 55px)",
				top: "71%",
			},
			// {
			// 	position: "absolute",
			// 	left: "calc(17% - 55px)",
			// 	top: "60.5%",
			// },
			{
				position: "absolute",
				left: "calc(15% - 55px)",
				top: "43.25%",
			},
			// {
			// 	position: "absolute",
			// 	left: "calc(17% - 55px)",
			// 	top: "26%",
			// },
			{
				position: "absolute",
				left: "calc(38% - 55px)",
				top: "15%",
			},
		],
		8: [
			{
				position: "absolute",
				left: "calc(70.5% - 55px)",
				top: "15%",
			},
			{
				position: "absolute",
				left: "calc(91% - 55px)",
				top: "26%",
			},
			// {
			// 	position: "absolute",
			// 	left: "calc(93% - 55px)",
			// 	top: "43.25%",
			// },
			{
				position: "absolute",
				left: "calc(91% - 55px)",
				top: "60.5%",
			},
			{
				position: "absolute",
				left: "calc(70.5% - 55px)",
				top: "71%",
			},
			{
				position: "absolute",
				left: "calc(38% - 55px)",
				top: "71%",
			},
			{
				position: "absolute",
				left: "calc(17% - 55px)",
				top: "60.5%",
			},
			// {
			// 	position: "absolute",
			// 	left: "calc(15% - 55px)",
			// 	top: "43.25%",
			// },
			{
				position: "absolute",
				left: "calc(17% - 55px)",
				top: "26%",
			},
			{
				position: "absolute",
				left: "calc(38% - 55px)",
				top: "15%",
			},
		],
		9: [
			{
				position: "absolute",
				left: "calc(70.5% - 55px)",
				top: "15%",
			},
			{
				position: "absolute",
				left: "calc(91% - 55px)",
				top: "26%",
			},
			{
				position: "absolute",
				left: "calc(93% - 55px)",
				top: "43.25%",
			},
			{
				position: "absolute",
				left: "calc(91% - 55px)",
				top: "60.5%",
			},
			{
				position: "absolute",
				left: "calc(70.5% - 55px)",
				top: "71%",
			},
			{
				position: "absolute",
				left: "calc(38% - 55px)",
				top: "71%",
			},
			{
				position: "absolute",
				left: "calc(17% - 55px)",
				top: "60.5%",
			},
			// {
			// 	position: "absolute",
			// 	left: "calc(15% - 55px)",
			// 	top: "43.25%",
			// },
			{
				position: "absolute",
				left: "calc(17% - 55px)",
				top: "26%",
			},
			{
				position: "absolute",
				left: "calc(38% - 55px)",
				top: "15%",
			},
		],
		10: [
			{
				position: "absolute",
				left: "calc(70.5% - 55px)",
				top: "15%",
			},
			{
				position: "absolute",
				left: "calc(91% - 55px)",
				top: "26%",
			},
			{
				position: "absolute",
				left: "calc(93% - 55px)",
				top: "43.25%",
			},
			{
				position: "absolute",
				left: "calc(91% - 55px)",
				top: "60.5%",
			},
			{
				position: "absolute",
				left: "calc(70.5% - 55px)",
				top: "71%",
			},
			{
				position: "absolute",
				left: "calc(38% - 55px)",
				top: "71%",
			},
			{
				position: "absolute",
				left: "calc(17% - 55px)",
				top: "60.5%",
			},
			{
				position: "absolute",
				left: "calc(15% - 55px)",
				top: "43.25%",
			},
			{
				position: "absolute",
				left: "calc(17% - 55px)",
				top: "26%",
			},
			{
				position: "absolute",
				left: "calc(38% - 55px)",
				top: "15%",
			},
		],
	};
	const avatarPosition = {
		2: [
			{
				position: "absolute",
				left: "calc(87.5% - 5px)",
				top: "35%",
				borderRadius: "50%"
			}, {
				position: "absolute",
				left: "calc(14.5% - 55px)",
				top: "35%",
				borderRadius: "50%"
			}],
		3: [
			{
				position: "absolute",
				left: "calc(67.5% - 35px)",
				top: "0%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(67.5% - 35px)",
				top: "60%",
				borderRadius: "50%"
			},
			// {
			// 	position: "absolute",
			// 	left: "calc(34.5% - 35px)",
			// 	top: "60%",
			// 	borderRadius: "50%"
			// },
			{
				position: "absolute",
				left: "calc(34.5% - 35px)",
				top: "0%",
				borderRadius: "50%"
			},
		],
		4: [
			{
				position: "absolute",
				left: "calc(67.5% - 35px)",
				top: "0%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(67.5% - 35px)",
				top: "60%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(34.5% - 35px)",
				top: "60%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(34.5% - 35px)",
				top: "0%",
				borderRadius: "50%"
			},
		],
		5: [
			{
				position: "absolute",
				left: "calc(67.5% - 35px)",
				top: "0%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(87.5% - 35px)",
				top: "30%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(67.5% - 35px)",
				top: "60%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(34.5% - 35px)",
				top: "60%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(34.5% - 35px)",
				top: "0%",
				borderRadius: "50%"
			},
		],
		6: [
			{
				position: "absolute",
				left: "calc(67.5% - 5px)",
				top: "5%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(87.5% - 5px)",
				top: "35%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(67.5% - 5px)",
				top: "65%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(34.5% - 55px)",
				top: "65%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(14.5% - 55px)",
				top: "35%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(34.5% - 55px)",
				top: "5%",
				borderRadius: "50%"
			},
		],
		7: [
			{
				position: "absolute",
				left: "calc(67.5% - 35px)",
				top: "0%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(87.5% - 35px)",
				top: "20%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(87.5% - 35px)",
				top: "40%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(67.5% - 35px)",
				top: "60%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(34.5% - 35px)",
				top: "60%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(14.5% - 35px)",
				top: "30%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(34.5% - 35px)",
				top: "0%",
				borderRadius: "50%"
			},
		],
		8: [
			{
				position: "absolute",
				left: "calc(67.5% - 35px)",
				top: "0%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(82.5% - 35px)",
				top: "15%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(82.5% - 35px)",
				top: "65%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(67.5% - 35px)",
				top: "60%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(34.5% - 35px)",
				top: "60%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(19.5% - 35px)",
				top: "45%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(19.5% - 35px)",
				top: "15%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(34.5% - 35px)",
				top: "0%",
				borderRadius: "50%"
			},
		],
		9: [
			{
				position: "absolute",
				left: "calc(67.5% - 35px)",
				top: "0%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(82.5% - 35px)",
				top: "15%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(87.5% - 35px)",
				top: "30%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(82.5% - 35px)",
				top: "45%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(67.5% - 35px)",
				top: "60%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(34.5% - 35px)",
				top: "60%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(19.5% - 35px)",
				top: "45%",
				borderRadius: "50%"
			},

			{
				position: "absolute",
				left: "calc(19.5% - 35px)",
				top: "15%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(34.5% - 35px)",
				top: "0%",
				borderRadius: "50%"
			},
		],
		10: [
			{
				position: "absolute",
				left: "calc(67.5% - 35px)",
				top: "0%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(82.5% - 35px)",
				top: "15%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(87.5% - 35px)",
				top: "30%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(82.5% - 35px)",
				top: "45%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(67.5% - 35px)",
				top: "60%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(34.5% - 35px)",
				top: "60%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(19.5% - 35px)",
				top: "45%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(14.5% - 35px)",
				top: "30%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(19.5% - 35px)",
				top: "15%",
				borderRadius: "50%"
			},
			{
				position: "absolute",
				left: "calc(34.5% - 35px)",
				top: "0%",
				borderRadius: "50%"
			},
		],
	};

	const styleSeat_vacant = {
		border: "2px solid #dbdbdd",
		borderRadius: "3px",
	};
	const styleSeat_taken = {
		border: "2px solid #ffe555",
		borderRadius: "3px",
	};

	const Seat = (data) => {
		// const getPlayersAvatars = (playerAvatar) => {

		// 	if (playerAvatar !== undefined) {
		// 		if (playerAvatar.indexOf("data:image/png;base64,") !== -1) {
		// 			let base64Data = playerAvatar.split("data:image/png;base64,")[1];
		// 			return base64Data;
		// 		} else {
		// 			const lastThreeChars = playerAvatar.slice(-3);
		// 			const totalAvatars = data.avatarList;
		// 			if (Object.values(totalAvatars).length) {
		// 				for (const avatar of totalAvatars) {
		// 					if (avatar.id.endsWith(lastThreeChars)) {
		// 						return avatar.imageData;
		// 					}
		// 				}
		// 			}
		// 		}
		// 	}
		// 	return null;
		// }
		return (
			<>
				{/* {data.avatarId && <img style={data.avtrPosition} height={40} width={40} src={`data:image/jpeg;base64,${getPlayersAvatars(data.avatarId)}`} alt=""></img>} */}
				<div style={data.position}>
					<div style={data.seatState}>
						<div className="tablePreviewSeat">
							<div className="nameField">{data.name}</div>
							<div className="chipsField">{UM.numberWithCommas(data.chips)}</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	const [tableSeats, setTableSeats] = useState({});
	useEffect(() => {
		console.log(props.cashTableData.seats)
		setTableSeats(props.cashTableData.seats);
	}, [props.cashTableData]);

	return (

		<div className="preview-table-container river_border">
			<img className="preview-table" src={table} alt="" />
			<div className="tablePreviewSeatsContainer">
				{tableSeats && tableSeats.length > 0 &&
					Array.from({ length: tableSeats.length }).map((_, i) => {
						return (
							<Seat
								key={i}
								seatState={tableSeats[i].taken === 0 ? styleSeat_vacant : styleSeat_taken}
								position={seatPostion[tableSeats.length][i]}
								avtrPosition={avatarPosition[tableSeats.length][i]}
								name={tableSeats[i].player}
								chips={tableSeats[i].chips}
							/>
						);
					})}


			</div>
		</div>
		// <div className="tableDetailsPreview">
		// 	<div className="tablePreviewCarpet"></div>
		// 	<div className="tablePreviewTable"></div>
		// 	<div className="tablePreviewSeatsContainer">
		// 		{(() => {
		// 			let i = 0,
		// 				cnt = props.seats.length,
		// 				seats = [];
		// 			for (i; i < cnt; i++) {
		// 				if (props.seats[i].taken === undefined) {
		// 					return;
		// 				}
		// 				seats.push(<Seat avatarList={props.avatarList} key={i} seatState={(props.seats[i].taken === 0) ? styleSeat_vacant : styleSeat_taken}
		// 					position={seatPostion[cnt][i]} avtrPosition={avatarPosition[cnt][i]} name={props.seats[i].player} avatarId={props.seats[i].avatarId} chips={props.seats[i].chips}></Seat>);
		// 			}
		// 			return seats;
		// 		})()}
		// 	</div>
		// </div>
	);
}
