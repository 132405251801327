import React, { useMemo, useState, useEffect, useRef } from "react";
import "../../../../../css/ui/lobby/mainGrid.css";
import Columns from "./mainGridColumnsSitGo";
import { useTable, useRowSelect, useSortBy } from "react-table";
// import eventBus from "../../../../utils/eventEmitter";
import Config from "../../../../../config";

export const MainGridSitGo = (props) => {
  const config = new Config();
  const columns = useMemo(() => Columns.Columns, []);
  const data = useMemo(() => props.data, [props.data]);

  const table = useTable(
    { columns, data, initialState: {} },
    useSortBy,
    useRowSelect
  );

  const {
    getTableProps,
    getTableBodyProps,
    rows,
    prepareRow,
  } = table;

  const [prevRow, setPrevRow] = useState(null);
  const [style, setStyle] = useState(false);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    // if (scrollContainerRef.current) {
    //   scrollContainerRef.current.scrollTo({
    //     top: 0,
    //     left: 0,
    //     behavior: 'smooth'
    //   });
    // }
    if (props.data[0].name === "No Table Matching Your Search Criteria") {
      setStyle(true);
    } else {
      setStyle(false);
    }
  }, [props.data]);

  // const handleRowClick = (row) => {
  //   return (event) => {
  //     if (row.original.type === "SITANDGO_TOURNAMENT") {
  //       if (prevRow) {
  //         prevRow.style.borderBottom = null;
  //       }
  //       const rowElement = event.currentTarget;
  //       setPrevRow(rowElement);
  //       const id = row.original.id;

  //       if (id) {
  //         props.setAction("showTourneyLobby");
  //         eventBus.emit('OpenTourneyLobby');
  //         console.log("test-mainGridSitGo");
  //         props.network.send(`<GetTableDetails id="${id}" type="${row.original.type}"/>`);
  //         props.network.send(`<OpenTable id="${id}" type="${row.original.type}"/>`);
  //         rowElement.style.borderBottom = "1px solid #3ba14c";
  //       }
  //     }
  //   };
  // };

  const [singleClick, setSingleClick] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width > 1200) {
        setSingleClick(false);
      } else {
        setSingleClick(true);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const rows = document.getElementsByTagName("tr");
    Array.from(rows).forEach(row => {
      const id = row.dataset.id;
      const type = row.dataset.type;
      const isSeated = props.data.some(table => row.innerText.includes(table.name));
      row.classList.toggle("seated", isSeated);
      if (type === "SITANDGO_TOURNAMENT") {
        row.onclick = () => {
          // if (row.innerText.includes("Seats")) return;
          if (prevRow) {
            prevRow.style.borderBottom = null;
          }

          if (id) {
            props.network.send(`<GetTableDetails id="${id}" type="${type}"/>`);
            row.style.borderBottom = "1px solid #3ba14c";
            if (singleClick) {
              props.network.send(`<OpenTable id="${id}" type="${type}"/>`);
              props.setAction("mainGridDblClick");
            }
          }
          setPrevRow(row);
          if (!singleClick) {
            props.setOpenAction("openSideMiniTable");
            row.ondblclick = () => {
              if (id) {
                props.network.send(`<OpenTable id="${id}" type="${type}"/>`);
                props.setAction("mainGridDblClick");
              }
            }
          }
        };
      };
    });

    return () => {
      Array.from(rows).forEach(row => {
        row.onclick = null;
      });
    };
  }, [prevRow, props.data, props.network, props.setAction]);


  const openDropDown = (e) => {
    if (e.target.name === "stakes_filter") {
      const Stake_Sort_Fliter_Tab = document.getElementById("Stake_Sort_Fliter");
      const stakes_filter_toggle = document.getElementById("sitgo_stakes_filter_toggle");
      if (Stake_Sort_Fliter_Tab) {
        Stake_Sort_Fliter_Tab.style.display = 'flex';
        stakes_filter_toggle.classList.add('rotate_smooth');
      }
    } else {
      const Seats_Sort_Fliter = document.getElementById("Seats_Sort_Fliter");
      const tourney_seats_filter_toggle = document.getElementById("sitgo_seats_filter_toggle");
      if (Seats_Sort_Fliter) {
        Seats_Sort_Fliter.style.display = 'flex';
        tourney_seats_filter_toggle.classList.add('rotate_smooth');
      }
    }
  };


  return (
    <div className="mainGrid mainGridOverflow" ref={scrollContainerRef}>
      <table id="mainGrid_table_rv" {...getTableProps()}>
        <thead>
          <div className="df table_header_row" style={{ width: '100%', alignItems: 'center' }}>
            {/* <div className="df col-12" style={{ color: '#ffff', padding: '0px 0px 0px 15px' }}>
              <span style={{ width: '70px' }}></span>
              <span style={{ width: '32%' }}>TABLE NAME</span>
              <span style={{ width: "15.8%" }} >GAEME</span>
              <button className="table_type_dropdown df_al" style={{ width: "15.8%" }} name="stakes_filter" onClick={(e) => openDropDown(e)}>BUY-IN'S<span className="m_l_20" id="stakes_filter">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b79301">
                  <path d="M480-328 225-583h510L480-328Z" />
                </svg>
              </span> </button>
              <span style={{ width: "15.8%" }}>REGISTERED PLAYERS</span>
              <button className="table_type_dropdown df_al" style={{ width: "15.8%" }} name="seats_filter" onClick={(e) => openDropDown(e)}>STATUS<span className="m_l_20" id="seats_filter">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b79301">
                  <path d="M480-328 225-583h510L480-328Z" />
                </svg>
              </span>
              </button>
            </div> */}
            <span style={{ width: '70px' }}></span>
            <div className="df row" style={{ color: '#ffff', padding: '0px 0px 0px 15px' }}>
              <span className="col-lg-4 col-xl-4 col-xxl-4 bold">Name</span>
              <span className="col-lg-2 col-xl-2 col-xxl-2 bold" >Game</span>
              <button className="table_type_dropdown df_al col-lg-2 col-xl-2 col-xxl-2 bold" name="stakes_filter" onClick={(e) => openDropDown(e)}>Buy-In
                <span className="m_l_20" id="sitgo_stakes_filter_toggle">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b79301">
                    <path d="M480-328 225-583h510L480-328Z" />
                  </svg>
                </span> </button>
              <span className="col-lg-2 col-xl-2 col-xxl-2 bold">Plrs.</span>
              <button className="table_type_dropdown df_al col-lg-2 col-xl-2 col-xxl-2 bold" name="seats_filter" onClick={(e) => openDropDown(e)}>Status
                <span className="m_l_20" id="sitgo_seats_filter_toggle">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b79301">
                    <path d="M480-328 225-583h510L480-328Z" />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr
                key={row.id}
                data-id={row.original.id}
                data-type={row.original.type}
              // {...row.getRowProps({ onClick: handleRowClick(row) })}
              >
                {row.cells.map(cell => (
                  <td key={cell.id} {...cell.getCellProps()} style={{ textAlign: style ? 'center' : '' }}>
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MainGridSitGo;
